import React from 'react';
import { withStyles } from '@material-ui/styles';
import { LoggedWithNavContainer } from '../../layout';
import { CardButtonsPanel, HomeFooter } from '../components';
import { useLoggedUser } from '@zetti/zweb-authenticated/dist/hooks';
import { ZCOLOR_ZETTI_BRAND } from '@zetti/zweb-react-commons/dist/components/ui/ZColorPalette';

const styles = {
  root: {
    flex: 1,
    fontFamily: 'Open Sans',
  },
  userNameContainer: {
    color: ZCOLOR_ZETTI_BRAND,
    fontFamily: 'Open Sans',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    maxWidth: '1440px',
    margin: '0 auto',
    width: '100%',
    padding: '20px 30px',
    height: 'fit-content'
  },
};

const HomeScreen = ({ classes }) => {
  const user = useLoggedUser();

  return (
    <LoggedWithNavContainer>
      <div style={styles.root}>
        <div style={styles.userNameContainer}>{user && user?.alias}</div>
        <CardButtonsPanel />
        <CardButtonsPanel fixedMenuOptions={true} />
      </div>
      <HomeFooter />
    </LoggedWithNavContainer>
  );
};

export default withStyles(styles)(HomeScreen);
