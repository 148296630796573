import {handleActions} from 'redux-actions';
import {actionTypes} from './actions';

export default handleActions(
    {
        [actionTypes.LOGIN_REQUESTED]: (state) => ({...state, loading: true, error: false, userData: null}),
        [actionTypes.LOGIN_SUCCESS]: (state, {payload: {userData}}) => {
            return ({
                ...state,
                logged: true,
                loading: false,
                error: false,
                userData
            })
        },
        [actionTypes.LOGIN_FAILURE]: (state) => ({
            ...state,
            logged: false,
            loading: false,
            error: true,
            userData: null
        }),
        [actionTypes.LOGOUT_SUCCESS]: (state) => ({
            ...state,
            logged: false,
            loading: false,
            error: false,
            userData: null
        }),
    },
    {
        logged: null,
        loading: true,
        error: null
    }
);
