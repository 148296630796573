import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/styles';
import {PublicAppBar} from '../../ui';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.custom.backgroundSmoke,
    height: '100%',
    overflow: 'auto'
  },
  section: {
    backgroundColor: theme.palette.custom.backgroundSmoke,
  }
});

const PublicContainer = ({children, classes}) => {
  return (
    <div className={classes.root}>
      <PublicAppBar/>
      <div className={"section-container "+classes.section}>
        {children}
      </div>
    </div>
  );
};

PublicContainer.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.any
}

export default withStyles(styles)(PublicContainer);