import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { ZCOLOR_050, ZCOLOR_060, ZCOLOR_070 } from "@zetti/zweb-react-commons/dist/components/ui/ZColorPalette";
import { MailIcon, PhoneInTalkIcon, TravelExploreIcon, WhatsappIcon } from "@zetti/zweb-react-commons/dist/components/ui/icons";
import { Theme } from "@material-ui/core";


const HomeFooter = ({ ...restOfProps }) => {

    const useStyles = makeStyles((theme: Theme) => createStyles({
        footerContainer: {
            width: "100%",
            borderRadius: "8px",
            border: `1px solid ${ZCOLOR_060}`,
            alignItems: "start",
            paddingTop: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
            color: ZCOLOR_060,
            fontFamily: "Open Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            justifyContent: 'space-around',
            padding: "5px",
            flexDirection: 'row',
            textAlign: 'center',
            display: "grid",
            //@ts-ignore
            [theme.breakpoints.up("tiny")]: {
                gridTemplateColumns: "repeat(2, 1fr)",
                gridGap: "30px 20px",
            },
            //@ts-ignore
            [theme.breakpoints.up("md")]: {
                maxWidth: "1340px",
                gridTemplateColumns: "repeat(4, 1fr)",
                gridGap: "20px",
            },
        },

        sectionTitle: {
            color: ZCOLOR_070,
            fontFamily: "Open Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal"
        },
        linksList: {
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
            padding: 0,
            "& > li": {
                color: ZCOLOR_060,
                listStyle: "none !important",
                fontFamily: "Open Sans",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "28px",
                padding: 0,
                whiteSpace: "nowrap",
            }
        },
        linksListItem: {
            cursor: "pointer"
        },
        contactList: {
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
            padding: 0,
            "& > li": {
                color: ZCOLOR_060,
                listStyle: "none !important",
                fontFamily: "Open Sans",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "28px",
                padding: 0,
                paddingBottom: "10px",
                whiteSpace: "nowrap"
            }
        },
        footerSection: {
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            flexDirection: "column",
            maxWidth: "305px",
        },
    }))

    const classes = useStyles()



    return (<div style={{
        display: "flex",
        overflow: "hidden",
        justifyContent: "center",
        marginBottom: "60px"
    }}
        {...restOfProps}
    >
        <footer
            className={classes.footerContainer}
        >
            <section
                className={classes.footerSection}>
                <img src="images/cafabo_logo_gris.png" alt="Cafabo"
                    style={{ width: "140px", height: "40.35px", userSelect: "none", pointerEvents: "none" }}
                    draggable={false}
                    onSelect={event => event.preventDefault()}
                />
            </section>

            <section
                className={classes.footerSection} >
                <h4 className={classes.sectionTitle} >CAFABO</h4>
                <ul className={classes.linksList} >
                    <li>
                        Cámara de Farmacias Bonaerense

                    </li>
                    <li>
                        25 de Mayo 624, Morón
                    </li>
                    <li>
                        Buenos Aires - Argentina
                    </li>
                </ul>
            </section>

            <section
                className={classes.footerSection} >
                <h4 className={classes.sectionTitle} >Links de utilidad</h4>
                <ul className={classes.linksList} >
                    <li
                        className={classes.linksListItem}
                        onClick={() => window.open("https://infofarma.aplifacaf1.com.ar/login", '_blank')}
                    >
                        Infofarma FACAF
                    </li>
                    <li
                        className={classes.linksListItem}
                        onClick={() => window.open("http://cafabo.xeilon.com.ar/crw/login.php", '_blank')}
                    >
                        Validador CAFABO
                    </li>
                    <li
                        className={classes.linksListItem}
                        onClick={() => window.open("https://www.preserfar.com/", '_blank')}
                    >
                        Preserfar
                    </li>
                    <li
                        className={classes.linksListItem}
                        onClick={() => window.open("https://www.farmalink.com.ar/home/#/", '_blank')}
                    >
                        Farmalink
                    </li>
                    <li
                        className={classes.linksListItem}
                        onClick={() => window.open("https://farma.pami.org.ar/seguridad/iniciar-sesion", '_blank')}
                    >
                        Farmapami
                    </li>
                </ul>
            </section>

            <section
                className={classes.footerSection}
            >
                <h4 className={classes.sectionTitle} >Contacto</h4>
                <ul className={classes.contactList} >
                    <li>
                        <PhoneInTalkIcon fill={ZCOLOR_050} />  (011) 4483-4488 | 5263-2134
                    </li>
                    <li>
                        <MailIcon fill={ZCOLOR_050} /> cafabo@cafabo.org.ar
                    </li>
                    <li
                        className={classes.linksListItem}
                        onClick={() => window.open("https://wa.me/5491134186782", '_blank')}
                    >
                        <WhatsappIcon fill={ZCOLOR_050} /> Mesa de ayuda CAFABO
                    </li>
                    <li
                        className={classes.linksListItem}
                        onClick={() => window.open("https://www.cafabo.org.ar/main/", '_blank')}
                    >
                        <TravelExploreIcon fill={ZCOLOR_050} /> www.cafabo.org.ar
                    </li>
                </ul>
            </section>
        </footer>
    </div>)

}

export default HomeFooter;