import React, { CSSProperties } from 'react';
import { ZWebAuthenticated } from '../../../../services';
import t from './translations';
import { withRouter } from 'react-router-dom';
import { ZButton, ZWIDTH_MD, ZWIDTH_TINY, useScreenWidth } from '@zetti/zweb-react-commons';
import {
  ZCOLOR_000,
  ZCOLOR_040,
  ZCOLOR_060,
  ZCOLOR_090,
} from '@zetti/zweb-react-commons/dist/components/ui/ZColorPalette';
import { Button, createStyles, makeStyles } from '@material-ui/core';

const PasswordRecoveryForm = ({ goBackToLogin }: { goBackToLogin: () => void }) => {
  const screenWidth = useScreenWidth();
  const useStyles = makeStyles((theme) =>
    createStyles({
      formContainer: {
        width: screenWidth === ZWIDTH_MD || screenWidth === ZWIDTH_TINY ? '360px' : '400px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      },
      headerText: {
        color: ZCOLOR_090,
        textAlign: 'center',
        fontFamily: 'Open Sans',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
      },

      recovery: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginTop: '1.7vh',
        paddingLeft: '1.9vw',
        paddingRight: '1.9vw',
      },
      recoveryLabel: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: 'normal',
        color: ZCOLOR_060,
        textAlign: 'center',
      },
      buttonLabel: {
        textAlign: 'center',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        textTransform: 'initial',
        fontWeight: 600,
        padding: '0.5rem 0',
      },
      button: {
        width: '100%',
        marginTop: '3.3vh',
        backgroundColor: theme.palette.primary.main,
        paddingLeft: '15px',
        paddingRight: '15px',
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'inline-flex',
        '&:hover': {
          color: ZCOLOR_000,
          fill: ZCOLOR_000,
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), ${theme.palette.primary.main}`,
        },
        '&:active': {
          color: ZCOLOR_000,
          fill: ZCOLOR_000,
          backgroundColor: `linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%),${theme.palette.primary.main}`,
        },
        '&$disabled': {
          color: ZCOLOR_000,
          backgroundColor: ZCOLOR_040,
        },
      },
      disabled: {},
    })
  );
  const classes = useStyles({});

  return (
    <div className={classes.formContainer}>
      <header className={classes.headerText}>{t('passRecoveryTitle')}</header>
      <section className={classes.recovery}>
        <div className={classes.recoveryLabel}>{t('passRecoveryLabel')}</div>
      </section>

      <Button variant="text" onClick={goBackToLogin} color="secondary" className={classes.button}>
        <div className={classes.buttonLabel}>{t('backBtn')}</div>
      </Button>
    </div>
  );
};

export default ZWebAuthenticated.hocs.WithTranslationRerender(withRouter(PasswordRecoveryForm));
