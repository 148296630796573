import React from "react";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import {withStyles} from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import PerfectScrollBar from "react-perfect-scrollbar";
import {ZWebAuthenticated} from "../../../../services";
import classNames from "classnames";
import {get, set} from "lodash";
import styles from "./styles";
import t from "./translations";
import { ZCard } from "@zetti/zweb-react-commons";

class EntitySelectorComponent extends React.Component {
    constructor(props) {
        super(props);
        this.itemsRefs = {};
        this.menuContainerRef = null;
        this.state = {
            filter: "",
            inputRef: null,
            entitiesFiltered: [],
            currentSelected: null
        };
    }

    handleKeyPress = event => {
        const {entitiesFiltered, currentSelected} = this.state;

        switch (event.keyCode) {
            case 38: // up - prev
                this.goToPrevieus(event);
                break;
            case 40: // down - next
                this.goToNext(event);
                break;
            case 13: // enter
                if (entitiesFiltered[currentSelected]) this.onSelectEntity(entitiesFiltered[currentSelected]);
                break;
            case 27: //esc
                this.selectItem(null);
                this.handleChange("");
                break;
        }
    };

    initFilteredEntities = () => {
        this.setState({
            entitiesFiltered: this.props.entities.slice(0, 20)
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.entities != prevProps.entities) {
            this.initFilteredEntities();
        }
    }

    componentWillMount() {
        if (this.props.entities) {
            this.initFilteredEntities();
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyPress, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress, false);
    }

    goToNext() {
        const {currentSelected, entitiesFiltered} = this.state;
        if (currentSelected === null) {
            this.selectItem(0);
            return;
        }

        if (currentSelected < entitiesFiltered.length - 1) {
            this.selectItem(currentSelected + 1);
        }
    }

    goToPrevieus() {
        const {currentSelected} = this.state;
        if (currentSelected > 0) {
            this.selectItem(currentSelected - 1);
        }
    }

    selectItem(index) {
        const {entitiesFiltered} = this.state;
        this.setState({currentSelected: index});
        this.scrollToSelected(entitiesFiltered[index]);
    }

    onSelectEntity(entity) {
        const {selectEntity} = this.props;
        selectEntity(entity);
    }

    handleChange(entitiesFilter) {
        const {entities} = this.props;

        let entitiesFiltered;
        this.setState({filter: entitiesFilter});

        if (!entitiesFilter || entitiesFilter === "") {
            entitiesFiltered = entities.slice(0, 20);
        }
        entitiesFiltered = entities
            .filter(
                entity =>
                    entity.nombre.search(entitiesFilter.toUpperCase()) > -1 ||
                    (entity.nombreCorto &&
                        entity.nombreCorto.search(entitiesFilter.toUpperCase()) > -1)
            )
            .slice(0, 20);
        this.selectItem(null);
        this.setState({entitiesFiltered: entitiesFiltered});
    }

    insideScreen(elementOffsetTop) {
        const containerOffsetHeight = get(
            this,
            "menuContainerRef._container.offsetHeight"
        );
        const containerScrollTop = get(
            this,
            "menuContainerRef._container.scrollTop"
        );

        return (
            elementOffsetTop < containerScrollTop + containerOffsetHeight &&
            elementOffsetTop > containerScrollTop
        );
    }

    scrollToSelected(selectedNode) {
        if (!selectedNode) {
            return;
        }

        let elementOffsetTop;
        let elementRef;

        elementRef = get(this, `itemsRefs[${selectedNode.id}]`);
        elementOffsetTop = get(elementRef, "offsetTop");

        if (!this.insideScreen(elementOffsetTop)) {
            const containerOffsetHeight = get(
                this,
                "menuContainerRef._container.offsetHeight"
            );
            const containerScrollTop = get(
                this,
                "menuContainerRef._container.scrollTop"
            );
            let scrollTo;

            // show in top
            if (containerScrollTop > elementOffsetTop) {
                scrollTo = elementOffsetTop;
            } else {
                // show in bottom
                const elementHeight = get(elementRef, "offsetHeight");
                const elementCompensation =
                    containerOffsetHeight > elementHeight
                        ? containerOffsetHeight - elementHeight
                        : 0;
                scrollTo = elementOffsetTop - elementCompensation;
            }
            set(this, "menuContainerRef._container.scrollTop", scrollTo);
        }
    }

    render() {
        const {entities, classes} = this.props;

        const {entitiesFiltered, filter, currentSelected} = this.state;
        return (
            <ZCard style={{height: '520px', width: '500px', padding: '0 0'}}>
                <List
                    component="nav"
                    subheader={
                        <div className={classes.HeaderContainer}>
                            <TextField
                                ref={"inputRef"}
                                id="entitySearch"
                                label={t("search", {plainText: true})}
                                fullWidth={true}
                                value={filter}
                                autoFocus={true}
                                autoComplete="off"
                                className={classes.textField}
                                onChange={e => this.handleChange(e.target.value)}
                                margin="normal"
                            />
                        </div>
                    }
                >
                    <PerfectScrollBar ref={ref => (this.menuContainerRef = ref)} style={{maxHeight: '420px'}}>
                        {(!entities || entities.length == 0) && (
                            <div className={classes.SpinnerContainer}>
                                <ZWebAuthenticated.components.Spinner styles={{flex: 1}}/>
                            </div>
                        )}
                        {entitiesFiltered &&
                        entitiesFiltered.map((entity, i) => (
                            <div
                                key={entity.id}
                                ref={ref => (this.itemsRefs[entity.id] = ref)}
                            >
                                <ZWebAuthenticated.components.ListItem
                                    button
                                    key={entity.id}
                                    onClick={() => {
                                        this.onSelectEntity(entity);
                                    }}
                                    className={classNames({
                                        [classes.selected]:
                                        currentSelected !== null && i === currentSelected
                                    })}
                                >
                                    <ZWebAuthenticated.components.ListItemAvatar
                                        classes={{root: classes.ListItemAvatar}}>
                                        <ZWebAuthenticated.components.LetterAvatar
                                            classes={{avatar: classes.Avatar}}
                                            letter={entity.nombre.charAt(0)}
                                        />
                                    </ZWebAuthenticated.components.ListItemAvatar>
                                    <ListItemText
                                        classes={{root: classes.ListItemText}}
                                        primary={entity.nombreCorto}
                                        secondary={entity.nombre}
                                    />
                                </ZWebAuthenticated.components.ListItem>
                            </div>
                        ))}
                    </PerfectScrollBar>
                </List>
            </ZCard>
        );
    }
}

export default withStyles(styles)(ZWebAuthenticated.hocs.WithTranslationRerender(EntitySelectorComponent));
