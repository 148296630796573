import { createAction } from 'redux-actions';

const moduleName='app';

export const actionTypes = {
    BOOTSTRAP_REQUEST: `${moduleName}|BOOTSTRAP_REQUEST`,
    BOOTSTRAP_SUCCESS: `${moduleName}|BOOTSTRAP_SUCCESS`,
    FETCH_SERVER_ALIAS_SUCCESS: `${moduleName}|FETCH_SERVER_ALIAS_SUCCESS`,
    FETCH_SERVER_ALIAS_FAILURE: `${moduleName}|FETCH_SERVER_ALIAS_FAILURE`,
    RESET_APP: `${moduleName}|RESET_APP`,
};

const bootstrapRequest = createAction(actionTypes.BOOTSTRAP_REQUEST);
const bootstrapSuccess = createAction(actionTypes.BOOTSTRAP_SUCCESS, (bootstrapped) => ({bootstrapped}));
const fetchServerAliasFailure = createAction(actionTypes.FETCH_SERVER_ALIAS_FAILURE);
const fetchServerAliasSuccess = createAction(actionTypes.FETCH_SERVER_ALIAS_SUCCESS);
const resetApp = createAction(actionTypes.RESET_APP);

export default {
    bootstrapRequest,
    bootstrapSuccess,
    fetchServerAliasFailure,
    fetchServerAliasSuccess,
    resetApp
}
