import React from 'react';
import {selectors as appSelectors} from '../../app';
import {connect} from 'react-redux';
import {Redirect} from 'react-router';
import {Route, withRouter} from "react-router-dom";
import {ZWebAuthenticated} from '../../../services';

const UnloggedRouteComponent = ({loginModuleLogged, appBootstraped, location, entitySelected, ...props}) => {

    if (!appBootstraped) {
        return null;
    }

    if (loginModuleLogged) {
        return <Redirect to="/"/>;
    }

    return (<Route {...props}/>);
};

const mapStateToProps = (state) => ({
    entitySelected: ZWebAuthenticated.selectors.entitySelected(state),
    loginModuleLogged: ZWebAuthenticated.selectors.isLogged(state),
    appBootstraped: appSelectors.isBootstraped(state)
});

export default withRouter(connect(mapStateToProps, null)(UnloggedRouteComponent));
