import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getFtwPhonesRequest } from "../modules/home/actions";

const useGetFtwPhones = () => {
    const dispatch = useDispatch();
    return useCallback(({ resolve, reject }) => {
        dispatch(getFtwPhonesRequest({ resolve, reject }));
    }, []);
};

export default useGetFtwPhones;