import React from 'react';
import { ZWebAuthenticated } from '../../../services';
import { withStyles } from "@material-ui/styles";
import { ZToolbarNEW } from '@zetti/zweb-react-commons';

const styles = theme => ({
  section: {
    backgroundColor: "#F8F8F8",
    height: "max-content",
    display: "grid",
  }
});

const LoggedContainer = ({ children, classes }) => {

  const { LoggedContainer } = ZWebAuthenticated.components;

  return (
    <LoggedContainer>
      <ZToolbarNEW
        withoutButtonBar
        breads={["Inicio"]}
        expandedToolbar={false}
      >
      </ZToolbarNEW>
      <div className={"section-container fullScreen " + classes.section}
      >
        {children}
      </div>
    </LoggedContainer>
  );
}

export default withStyles(styles)(LoggedContainer);