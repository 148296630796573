import { all, takeEvery, call, put } from 'redux-saga/effects'
import { actionTypes, default as actions } from './actions';
import { history } from '../app';

function* handleNavigate({ payload: { routeName, params } } = {}) {
    try {
        yield call([history, 'push'], routeName, params);
    } catch (e) {
        yield put(actions.navigateFaiulure());
    }
}


export default function* () {
    yield all([
        yield takeEvery(actionTypes.NAVIGATE_REQUESTED, handleNavigate),
    ]);
}
