import { all, take, takeEvery, put } from 'redux-saga/effects'
import { actionTypes, default as actions } from './actions';
import { actions as navigationActions } from '../navigation';
import { ZWebAuthenticated } from '../../services';

function* handleLogin({ payload: { username, password, redirect } } ) {
  try {
    yield put(ZWebAuthenticated.actions.authorizeRequest(username, password));

    const {payload: {authorized}} = yield take(ZWebAuthenticated.actionTypes.AUTHORIZE_SUCCESS);
    if (authorized) {
      const nextParam = redirect ? `?next=${redirect}` : "";

      yield put(navigationActions.navigate(`/select-entity${nextParam}`));
    } else {
      yield put(actions.loginFailure());
    }
  } catch (error) {
    yield put(actions.loginFailure());
  }
}


export default function* () {
  yield all([
    yield takeEvery<any>(actionTypes.LOGIN_REQUESTED, handleLogin),
  ]);
}
