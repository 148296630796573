import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { LoggedWithNavContainer } from '../../layout';
import { CafaboHomeFooter, CardButtonsPanel } from '../components';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flex: 1,
      fontFamily: 'Open Sans',
    },
    logotypeContainer: {
      maxWidth: '1440px',
      mixBlendMode: 'multiply',
      margin: '0 auto',
      padding: '20px 30px',
      width: '100%',
      display: "flex"
    },
    composedLogoContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }
  })
);

const CafaboHomeScreen = () => {
  const classes = useStyles();

  return (
    <LoggedWithNavContainer>
      <div className={classes.root}>
        <div className={classes.logotypeContainer}>
          <div className={classes.composedLogoContainer}>
            <img
              src="images/cafabo_logo.png"
              alt="Cafabo"
              style={{
                width: '220px', height: '81.54px', userSelect: 'none', cursor: 'pointer',
                objectFit: "cover"
              }}
              draggable={false}
              onSelect={(event) => event.preventDefault()}
              onClick={e => window.open("https://zetti.com.ar/", '_blank')}
            />
            <img
              src="images/powered_by_zetti_logo.png"
              alt="Powered By Zetti"
              style={{
                width: '138px', height: '20.5px', userSelect: 'none', cursor: 'pointer',
                margin: "0 auto"
              }}
              draggable={false}
              onSelect={(event) => event.preventDefault()}
              onClick={e => window.open("https://zetti.com.ar/", '_blank')}
            />
          </div>
        </div>
        <CardButtonsPanel />
        <CardButtonsPanel fixedMenuOptions={true} />
      </div>
      <CafaboHomeFooter />
    </LoggedWithNavContainer>
  );
};

export default CafaboHomeScreen;
