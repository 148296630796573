import { handleActions } from 'redux-actions';
import { actionTypes } from './actions';

export default handleActions(
    {
        [actionTypes.NAVIGATE_REQUESTED]: (state) => ({ ...state, error: null}),
        [actionTypes.NAVIGATE_FAILURE]: (state) => ({ ...state, error: true})
    },
    {
        error: null,
    }
);