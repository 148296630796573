import React from "react";
import {ZToolbar} from "@zetti/zweb-react-commons";
import {useServerAlias} from "../../app/hooks";
import LogoZetti from './LogoZetti'
import {createStyles, makeStyles} from "@material-ui/core";
import TYSwebLogo from "@zetti/zweb-authenticated/dist/components/DrawerMenu/TYSwebLogo"

const useStyles = makeStyles(() =>
  createStyles({
    menuLogo: {
      marginLeft: -12,
      marginRight: 10,
      color: 'white', 
      fontWeight: 'bold'
    },
    content: {
      display: "flex",
      flexGrow: 1,
      justifyContent: 'flex-end',
      alignItems: 'center'
    }
  })
);

const PublicAppBar = ({title="Login"}) => {
  const serverAlias = useServerAlias();
  const classes = useStyles();
  
  const TySBranding = () => {
    return (<>
      <TYSwebLogo/> <LogoZetti/> {serverAlias ? `\u00A0\u00A0[${serverAlias}]` : undefined}
      </>
    )
  }

  return (
    <ZToolbar withoutMenu={true} title={<TySBranding/>}
    appBarProps={{
      style: {
        color: '#ffffff',
        boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)"
      }
    }}>
     {/*  <div className={classes.content}>
        <span className={classes.menuLogo}>T&Sweb</span>
      </div> */}
    </ZToolbar>
  );
};

export default PublicAppBar;
