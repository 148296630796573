export default () => ({
    root: {},
    HeaderContainer: {
        padding: 15,
        paddingTop: 0
    },
    SpinnerContainer: {
        width: "100%",
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    selected: {
        backgroundColor: "#dee5e8 !important"
    },
    ListItemText: {
        paddingLeft: 16,
        paddingRight: 16
    },
    ListItemAvatar: {
        minWidth: 40,
        marginRight: 8
    },
    Avatar: {
        margin: 0,
    }
});
