import { handleActions } from 'redux-actions';
import { actionTypes } from "./actions"
import { Phone } from '@zetti/zweb-repository';
import { State } from './typings';


const initialState: State = {
    ftwPhones: []
}

export default handleActions({
    [actionTypes.GET_FTW_PHONES_SUCCESS]: (state: State, { payload: { phones } }: { payload: { phones: Phone[] } }) => ({
        ...state,
        ftwPhones: phones
    })
}, initialState);

