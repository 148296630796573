import { connect } from 'react-redux';
import EntitySelectorComponent from './EntitySelectorComponent';
import {ZWebAuthenticated} from '../../../../services';

const mapStateToProps = (state) => {
    const user = ZWebAuthenticated.selectors.userLogged(state);
    return {
        entities: user? user.entidades : null
    } 
};

const mapDispatchToProps = dispatch => {
    return {
        selectEntity: (entity) => {
            dispatch(ZWebAuthenticated.actions.selectEntity({entity}));
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EntitySelectorComponent);