import { all, call, put, select, takeEvery } from "redux-saga/effects";
import selectors from "./selectors"
import { ZWebResources } from "../../services";
import {
  getNoveltiesTokenRequest,
  getNoveltiesTokenRequested,
  getNoveltiesTokenSuccess,
  getNoveltiesTokenFailure,
  getFtwPhonesRequest,
  getFtwPhonesRequested,
  getFtwPhonesRequestSuccess,
  getFtwPhonesRequestFailure
} from "./actions";

import { Node, Token, User } from "@zetti/zweb-repository";


export function* handleGetNoveltiesAccessToken({
  payload: { resolve, reject }
}: {
  payload: { resolve: any, reject: any };
}) {
  try {
    yield put(getNoveltiesTokenRequested)
    const entitySelected: Node = yield select(selectors.entitySelected)
    let forNode = Node.deserialize(entitySelected, { version: 1 })
    forNode = Node.serialize(forNode, { version: 2 })

    const user: User = yield select(selectors.userLogged)

    //     const forNode = {
    //       // id:"188969",
    //        "id": "188969",
    //        "name": "PRUEBAS FTW",
    //        "hierarchicalCode": "000.001.999",
    //      }

    const token: Token = (yield call([ZWebResources.v2.tokenApi, "getNoveltiesAccessToken"], { forNode, user })).data;

    yield put(getNoveltiesTokenSuccess)
    yield call(resolve, token.hash)
  }
  catch (error) {
    yield put(getNoveltiesTokenFailure)
    yield call(reject, error)
    console.error(error)
  }
}


export function* handleSearchFtwPhones({
  payload: { resolve, reject }
}: {
  payload: { resolve: any, reject: any };
}) {
  try {
    yield put(getFtwPhonesRequested)
    const { phones }: Node = Node.serialize((yield call([ZWebResources.v2.nodeApi, "getById"], { idNode: "1", includePhones: true, includeAdresses: true })).data, { version: 2 });
    yield put(getFtwPhonesRequestSuccess({ phones }))
    yield call(resolve, phones)

  } catch (error) {
    console.error(error)
    yield put(getFtwPhonesRequestFailure)
    yield call(reject, error)
  }
}


export default function* () {
  yield all([
    yield takeEvery<string, any>(getNoveltiesTokenRequest, handleGetNoveltiesAccessToken),
    yield takeEvery<string, any>(getFtwPhonesRequest, handleSearchFtwPhones),
  ])
}
