import { ZWebAuthenticated } from '../../../../services';

export default ZWebAuthenticated.registerTranslations('LoginFormComponent', {
    es: {
        username: 'Nombre de usuario',
        password: 'Contraseña',
        loginBtn: 'Ingresar',
        wrongCredentials: 'Usuario o contraseña incorrectos.',
        rememberMe: 'Recordarme',
        recoveryPassword: '¿Olvidaste tu contraseña?',
        formTitle: '¡Hola! Te damos la bienvenida.'
    },
    pt: {
        username: 'Nome de usuário',
        password: 'Senha',
        loginBtn: 'Login',
        wrongCredentials: 'Nome de usuário ou senha errados.',
        rememberMe: 'Lembre-se de mim',
        recoveryPassword: 'Esqueceu sua senha?',
        formTitle: 'Olá! Bem-vindo.'
    },
    en: {
        username: 'Username',
        password: 'Password',
        loginBtn: 'Login',
        wrongCredentials: 'Wrong username or password.',
        rememberMe: 'Remember me',
        recoveryPassword: 'Forgot your password?',
        formTitle: 'Hello! Welcome.'
    }
});

