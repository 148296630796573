import {ZWebRepository} from '@zetti/zweb-repository';
import ZWebAuthenticated from './ZWebAuthenticated'

const ZWebRepositoryInstance = new ZWebRepository({
  api: ZWebAuthenticated.AuthenticatedApi,
  entityGetter: async (): Promise<any> => {
    return await ZWebAuthenticated.getEntitySelected();
  }
});

const ZWebResources = ZWebRepositoryInstance.resources;

export {ZWebResources};
