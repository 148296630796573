import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#00adee',
            contrastText: "#ffffff",
            light: "#ffffff"
        },
        secondary: {
            main: '#ffffff'
        },
        custom: {
            black: 'rgba(0, 0, 0, 0.87)',
            green: '#adc94e',
            backgroundSmoke: '#EEEEEE',
            errorColor: '#f1956f'
        }
    },
    customBars: {
        drawerWidth: 450
    },
    breakpoints: {
        keys: ["tiny", "sm", "md", "lg", "xl"],
        values: {
            tiny: 0,
            sm: 900,
            md: 1100,
            lg: 1440,
            xl: 1920
        }
    }
});

export default theme;