import React, { CSSProperties, useState } from 'react';
import LoginForm from '../components/LoginForm';
import { PasswordRecoveryForm } from '../components/PasswordRecovery';
import { OfficeWorkersDraw, PasswordRecoveryDraw } from '../components';
import { ZCOLOR_BACKGROUND } from '@zetti/zweb-react-commons/dist/components/ui/ZColorPalette';
import { createStyles, makeStyles } from '@material-ui/core';
import { ZWIDTH_SM, ZWIDTH_TINY, useScreenWidth } from '@zetti/zweb-react-commons';

const useStyles = makeStyles((theme) =>
  createStyles({
    rowContainer: {
      display: 'flex',
      width: '100vw',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
    },
    leftColumn: {
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      flex: 1,
      background: ZCOLOR_BACKGROUND,
    },
    rightColumn: {
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      background: '#E0F1F8',
    },
    workersDraw: {
      maxWidth: '100%',
      maxHeight: '100%',
      minHeight: '300px',
      minWidth: '260px',
      width: 'auto',
      height: 'auto',
      userSelect: 'none',
      pointerEvents: 'none',
      padding: '16px',
    },
    passwordRecoveryDraw: {
      maxWidth: '100%',
      maxHeight: ' 100%',
      width: 'auto',
      height: 'auto',
      userSelect: 'none',
      pointerEvents: 'none',
      padding: '16px',
    },
    disabled: {},
  })
);

const LoginScreen = () => {
  const classes = useStyles({});
  const screenWidth = useScreenWidth();

  const [enableRecoveryForm, setEnableRecoveryForm] = useState<boolean>(false);
  return (
    <div className={classes.rowContainer}>
      {!enableRecoveryForm ? (
        /*LOGIN*/
        <>
          <div className={classes.leftColumn}>
            <LoginForm goRecoveryForm={() => setEnableRecoveryForm(true)} />
          </div>

          {ZWIDTH_TINY !== screenWidth && (
            <div className={classes.rightColumn}>
              <img
                src={OfficeWorkersDraw}
                draggable={false}
                onSelect={(event) => event.preventDefault()}
                className={classes.workersDraw}
              />
            </div>
          )}
        </>
      ) : (
        /*FORM RECOVERY PASSWORD*/
        <>
          <div className={classes.leftColumn}>
            <PasswordRecoveryForm goBackToLogin={() => setEnableRecoveryForm(false)} />
          </div>

          {ZWIDTH_TINY !== screenWidth && (
            <div className={classes.rightColumn}>
              <img
                src={PasswordRecoveryDraw}
                draggable={false}
                onSelect={(event) => event.preventDefault()}
                className={classes.passwordRecoveryDraw}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LoginScreen;
