import { createAction } from 'redux-actions';

const moduleName='navigation';

export const actionTypes = {
    NAVIGATE_REQUESTED: `${moduleName}|NAVIGATE_REQUESTED`,
    NAVIGATE_FAILURE: `${moduleName}|NAVIGATE_REQUESTED`
};

const navigate = createAction(actionTypes.NAVIGATE_REQUESTED, (routeName, params) => ({routeName, params}));
const navigateFailure = createAction(actionTypes.NAVIGATE_FAILURE);

export default {
    navigate,
    navigateFailure
}