import React from "react";
import Grid from "@material-ui/core/Grid";
import EntitySelector from "../components/EntitySelector";
import { connect } from "react-redux";
import { ZWebAuthenticated } from "../../../services";
import { ZBackButton, ZToolbarNEW } from "@zetti/zweb-react-commons";
import { useLogout } from "../hooks";


const EntitySelectorScreen = () => {

  const logout = useLogout()

  return (<>
    <ZToolbarNEW
      withoutButtonBar
      withoutMenu
      withoutTitle
      breads={["Inicio"]}
      expandedToolbar={false}
      secondaryToolbarProps={{
        style: {
          display: "none",
          visibility: "hidden"
        }
      }}
    >
    </ZToolbarNEW>
    <div style={{
      width: '100%',
    }}>
      <div style={{
        justifyContent: "left",
        display: "flex",
        padding: 30,
      }}>
        <ZBackButton
          action={() => logout()}
          label="Salir"
        />
      </div>
      <EntitySelector />
    </div>
  </>
  );
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(ZWebAuthenticated.actions.logout())
});
export default connect(
  null,
  mapDispatchToProps
)(EntitySelectorScreen);