import { createAction } from 'redux-actions';

export const MODULE_NAME = 'home';

export const actionTypes = {
    GET_NOVELTIES_ACCESS_TOKEN_REQUEST: `${MODULE_NAME}|GET_NOVELTIES_ACCESS_TOKEN_REQUEST`,
    GET_NOVELTIES_ACCESS_TOKEN_REQUESTED: `${MODULE_NAME}|GET_NOVELTIES_ACCESS_TOKEN_REQUESTED`,
    GET_NOVELTIES_ACCESS_TOKEN_SUCCESS: `${MODULE_NAME}|GET_NOVELTIES_ACCESS_TOKEN_SUCCESS`,
    GET_NOVELTIES_ACCESS_TOKEN_FAILURE: `${MODULE_NAME}|GET_NOVELTIES_ACCESS_TOKEN_FAILURE`,

    GET_FTW_PHONES_REQUEST: `${MODULE_NAME}|GET_FTW_PHONES_REQUEST`,
    GET_FTW_PHONES_REQUESTED: `${MODULE_NAME}|GET_FTW_PHONES_REQUESTED`,
    GET_FTW_PHONES_SUCCESS: `${MODULE_NAME}|GET_FTW_PHONES_SUCCESS`,
    GET_FTW_PHONES_FAILURE: `${MODULE_NAME}|GET_FTW_PHONES_FAILURE`,
};


export const getNoveltiesTokenRequest = createAction(actionTypes.GET_NOVELTIES_ACCESS_TOKEN_REQUEST);
export const getNoveltiesTokenRequested = createAction(actionTypes.GET_NOVELTIES_ACCESS_TOKEN_REQUESTED);
export const getNoveltiesTokenSuccess = createAction(actionTypes.GET_NOVELTIES_ACCESS_TOKEN_SUCCESS);
export const getNoveltiesTokenFailure = createAction(actionTypes.GET_NOVELTIES_ACCESS_TOKEN_FAILURE);

export const getFtwPhonesRequest = createAction(actionTypes.GET_FTW_PHONES_REQUEST);
export const getFtwPhonesRequested = createAction(actionTypes.GET_FTW_PHONES_REQUESTED);
export const getFtwPhonesRequestSuccess = createAction(actionTypes.GET_FTW_PHONES_SUCCESS);
export const getFtwPhonesRequestFailure = createAction(actionTypes.GET_FTW_PHONES_FAILURE);


export default {
    getNoveltiesTokenRequest,
    getNoveltiesTokenRequested,
    getNoveltiesTokenSuccess,
    getNoveltiesTokenFailure,
    getFtwPhonesRequest,
    getFtwPhonesRequested,
    getFtwPhonesRequestSuccess,
    getFtwPhonesRequestFailure
}