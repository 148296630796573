import ZWebAuthenticatedService from '@zetti/zweb-authenticated';

const ZWebAuthenticated = new ZWebAuthenticatedService({
  apiBaseUrl: process.env.REACT_APP_API_URL,
  ftwebBaseUrl: process.env.REACT_APP_FTWEB_URL,
  authorizeUrl: process.env.REACT_APP_AUTHORIZE_URL,
  refreshUrl: process.env.REACT_APP_REFRESH_URL,
  clientId: process.env.REACT_APP_CLIENT_ID,
  clientSecret: process.env.REACT_APP_CLIENT_SECRET,
  userCredentialsUrl: process.env.REACT_APP_API_USER_PROFILE_URL,
  userPermissionsUrl: process.env.REACT_APP_API_USER_PERMISSION_URL,
  rootRedirectionBasePath: '../',
  onUnauthenticated: () => {}
});

export default ZWebAuthenticated;
