import {all, call, put, take, takeEvery} from 'redux-saga/effects'
import actions, {actionTypes} from './actions';
import {ZWebAuthenticated, ZWebResources} from '../../services';
import {actions as navigationActions} from '../navigation';

function* handleBootstrap() {
  yield put(ZWebAuthenticated.actions.bootstrapRequest());
  yield take(ZWebAuthenticated.actionTypes.BOOTSTRAP_SUCCESS);
  yield call(fetchAbout)
  yield put(actions.bootstrapSuccess());
}

function* fetchAbout() {
  try {
    const aboutResponse = yield call([ZWebResources.api, 'getAbout']);
    const serverAlias = aboutResponse.data.serverAlias;
    yield put(actions.fetchServerAliasSuccess({serverAlias}));
  } catch (error) {
    yield put(actions.fetchServerAliasFailure());
    //TODO: something
  }
}

function* handleSelectEntitySuccess({payload}) {
  if (payload.redirect) {
    yield call([window.location, 'assign'], payload.redirect);
  } else {
    yield put(navigationActions.navigate('/'));
  }
}

function* handleLogout() {
  // reset app state
  yield put(actions.resetApp());
  yield call(handleBootstrap);

}

export default function* () {
  yield all([
    yield takeEvery<any>(actionTypes.BOOTSTRAP_REQUEST, handleBootstrap),
    yield takeEvery<any>(ZWebAuthenticated.actionTypes.SELECT_ENTITY_SUCCESS, handleSelectEntitySuccess),
    yield takeEvery<any>(ZWebAuthenticated.actionTypes.LOGOUT_SUCCESS, handleLogout),
  ]);
}

