import { ZWebAuthenticated } from "../../../../services";

export default ZWebAuthenticated.registerTranslations("EntitySelectorComponent", {
  es: {
    search: "Seleccione una entidad"
  },
  pt: {
    search: "Seleccione una entidad"
  },
  en: {
    search: "Select entity"
  }
});
