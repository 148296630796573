import { connect } from 'react-redux';
import LoginForm from './LoginForm';
import { ZWebAuthenticated } from '../../../../services';
import actions from '../../actions';
import { compose, withProps } from "recompose";

const mapStateToProps = (state) => {
    return {
        loading: ZWebAuthenticated.selectors.isLoading(state),
        loginError: ZWebAuthenticated.selectors.loginError(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        login: (username, password, redirectTo) => dispatch(actions.login(username, password, redirectTo)),
    }
};

const withPropsHandling = compose(
    withProps(({ goRecoveryForm }) => {
        return {
            goRecoveryForm
        };
    }),
);

export default withPropsHandling(connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginForm));