import { useDispatch } from 'react-redux';
import { login } from "./actions";
import { useCallback } from "react";
import { ZWebAuthenticated } from '../../services';


export const useLogin = () => {
  const dispatch = useDispatch();
  return useCallback((username, password, redirect) => {
    dispatch(login(username, password, redirect))
  }, []);
};

export const useLogout = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(ZWebAuthenticated.actions.logout());
  }, []);
};


export default {
  useLogin,
  useLogout
};