import React from 'react';
import {ZWebAuthenticated} from '../../../services';
import {connect} from 'react-redux';
import {Route} from "react-router-dom";
import {Redirect, withRouter} from 'react-router';
import {selectors as appSelectors} from '../../app';

const LoggedRouteComponent = ({isLogged, appBootstraped, ...props}) => {
    if (!appBootstraped) {
        return null;
    }

    if (!isLogged) {
        return <Redirect to="/login"/>;
    }

    return <Route {...props} />;
};

const mapStateToProps = (state) => ({
    isLogged: ZWebAuthenticated.selectors.isLogged(state),
    appBootstraped: appSelectors.isBootstraped(state)
});

export default withRouter(connect(mapStateToProps, null)(LoggedRouteComponent));
