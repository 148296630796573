import React, { useCallback } from 'react';
import { ZCard, ZWIDTH_LG, ZWIDTH_MD, ZWIDTH_SM, ZWIDTH_TINY, ZWIDTH_XL, useCurrentLanguage, useScreenWidth } from '@zetti/zweb-react-commons';
import { createStyles, makeStyles } from '@material-ui/styles';
import {
  ZCOLOR_000,
  ZCOLOR_050,
  ZCOLOR_090,
  ZCOLOR_ZETTI_BRAND,
} from '@zetti/zweb-react-commons/dist/components/ui/ZColorPalette';
import { useEffect, useState } from 'react';
import { ZWebAuthenticated } from '../../../services';
import { compose } from 'recompose';
import _ from 'lodash';
import moment from "moment"
import 'moment/locale/es';
import 'moment/locale/pt';

const composer = compose(
  ZWebAuthenticated.hocs.WithOpenNodeHandler,
  ZWebAuthenticated.hocs.WithHistoryCollection({
    collectionName: ZWebAuthenticated.constants.HISTORY_COLLECTION_NODES_NAME,
    propName: 'nodes',
  })
);

const CardButtonsPanel = ({ nodes, onOpenNode, fixedMenuOptions = false, title, ...restOfProps }) => {
  const screenWidth = useScreenWidth();
  const getTemplateColumns = () => {

    if (screenWidth === ZWIDTH_XL || screenWidth === ZWIDTH_LG) return 'repeat(6, minmax(213.33px, 1fr))'
    if (screenWidth === ZWIDTH_MD) return 'repeat(3, minmax(333.33px, 1fr))'
    if (screenWidth === ZWIDTH_TINY || screenWidth === ZWIDTH_SM) return 'repeat(auto-fill, minmax(266.67px, 1fr))'
    else return 'repeat(auto-fill, minmax(213.33px, 1fr))'
  }


  const useStyles = makeStyles((theme) =>
    createStyles({
      container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        lineHeight: 'normal',
      },
      cardsContainer: {
        display: 'grid',
        gridTemplateColumns: getTemplateColumns(),
        gap: '20px',
        width: '100%',
        padding: '20px 30px',
      },
      cardButton: {
        backgroundColor: ZCOLOR_000,
        padding: '20px',
        boxSizing: 'border-box',
        borderRadius: '8px',
        boxShadow: '0px 2px 5px 0px #00000005',
        flexDirection: 'column',
        display: 'flex',
        textAlign: 'left',
        userSelect: 'none',
        minHeight: '110px',
        '&:hover': {
          background: ZCOLOR_ZETTI_BRAND,
          cursor: 'pointer',
          '& $menuTitle': {
            color: ZCOLOR_000,
          },
          '& $menuOptionTree': {
            color: ZCOLOR_000,
          },
        },
      },
      menuTitle: {
        color: ZCOLOR_090,
        fontSize: '16px',
        fontWeight: 400,
        flex: 1,
      },
      menuOptionTree: {
        color: ZCOLOR_050,
        fontSize: '14px',
        fontWeight: 400,
      },
      panelTitle: {
        color: ZCOLOR_090,
        fontSize: '18px',
        fontWeight: 600,
        display: 'flex',
        width: '100%',
        padding: '20px 30px',
        paddingBottom: '0'
      },
    })
  );

  const classes = useStyles();

  interface ZwebLinks {
    path: string;
    optionName: string;
    optionIndex: string;
  }

  const ZWEB_LINKS: ZwebLinks[] = [
    {
      path: '/tysweb/purchases/#/wizard',
      optionName: 'Compras',
      optionIndex: '1.1',
    },
    {
      path: '/tysweb/accounting/#/caea',
      optionName: 'CAEA',
      optionIndex: '5.7.10.1',
    },
    {
      path: '/tysweb/data-management/#/products-groups',
      optionName: 'Cambio global de atributos',
      optionIndex: '7.1.10',
    },
    {
      path: '/tysweb/accounting/#/iva-book',
      optionName: 'Mis comprobantes',
      optionIndex: '5.7.10.2',
    },
    {
      path: '/tysweb/purchases/#/active-orders',
      optionName: 'Pedidos activos',
      optionIndex: '1.2',
    },
    {
      path: '/tysweb/pay-settlements/#/os-presentations-board',
      optionName: 'Tablero presentaciones OS',
      optionIndex: '3.1.9',
    }
  ];

  const [historyNodes, setHistoryNodes] = useState(undefined);

  function getIndexedNodesWithName(nodesHistory: any[]) {
    let indexedNodesWithName = [];

    nodesHistory.forEach((node) => {
      let optionName = node.name.substring(node.name.indexOf(' ') + 1);
      let optionIndex = node.name.substring(0, node.name.indexOf(' '));
      let usedAt = node._usedAt;
      indexedNodesWithName.push({ historyNode: node, optionName, usedAt, optionIndex });
    });
    return indexedNodesWithName;
  }


  useEffect(() => {
    const getFilteredNodes = nodes?.filter(n => !n.name.toLowerCase().includes("inicio"))
    const sixOptions = _.slice(getFilteredNodes, 0, 6);
    const getNameAndIndex = getIndexedNodesWithName(sixOptions);
    setHistoryNodes(getNameAndIndex);
  }, [nodes]);

  const customTitle = title ? title : fixedMenuOptions ? 'Accesos rápidos' : 'Últimos utilizados';

  const currentLang = useCurrentLanguage()

  const parseTimeAgo = useCallback((openedDate) => {
    moment.locale(currentLang)
    let prefix = ""
    if (currentLang && currentLang !== "en") {
      if (currentLang === "es") prefix = "Hace "
      if (currentLang === "pt") prefix = "Ha "
    }
    const parsedTimeString = prefix + moment(openedDate).fromNow(true)
    return parsedTimeString;
  }, [currentLang])

  return (
    <div className={classes.container} {...restOfProps}>
      <div style={{ maxWidth: '1440px', width: '100%' }}>
        <span className={classes.panelTitle}>{customTitle}</span>
        <div
          className={classes.cardsContainer}
        >
          {(fixedMenuOptions ? ZWEB_LINKS : historyNodes)?.map((node, index) => {
            return (
              <ZCard
                key={index}
                closeButton={false}
                //@ts-ignore
                className={classes.cardButton}
                onClick={(e) => {
                  if (!fixedMenuOptions) onOpenNode(node?.historyNode, e.ctrlKey);
                  else window.location.href = `${process.env.REACT_APP_SERVER_URL}${node?.path}`;
                }}
              >
                <span className={classes.menuTitle}>{node.optionName}</span>
                {fixedMenuOptions &&
                  <span className={classes.menuOptionTree}>{node.optionIndex}</span>
                }
                {!fixedMenuOptions && <div style={{ display: "flex", placeContent: "space-between" }}>
                  <span className={classes.menuOptionTree}>{node.optionIndex}</span>
                  <span className={classes.menuOptionTree}>{parseTimeAgo(node.usedAt)}</span>
                </div>
                }
              </ZCard>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default composer(CardButtonsPanel);
