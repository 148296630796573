import {handleActions} from 'redux-actions';
import {actionTypes} from './actions';

export default handleActions(
  {
    [actionTypes.BOOTSTRAP_SUCCESS]: (state) => ({...state, bootstraped: true}),
    [actionTypes.FETCH_SERVER_ALIAS_SUCCESS]: (state, {payload: {serverAlias}}) => ({...state, serverAlias}),
  },
  {bootstraped: false, serverAlias: ""},
);
