import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import {combineReducers} from 'redux';
import {reducers as appReducers, sagas as appSagas, actionTypes} from './modules/app';
import {reducers as loginReducers, sagas as loginSagas} from './modules/login';
import {reducers as navigationReducers, sagas as navigationSagas} from './modules/navigation';
import {reducers as homeReducers, sagas as homeSagas} from './modules/home';
import {ZWebAuthenticated} from './services';
import createSagaMiddleware from 'redux-saga';
import {all} from 'redux-saga/effects'
import logger from 'redux-logger';
import ZWebReactCommons from "./services/ZWebReactCommons";

function* sagas() {
  return yield all([
    appSagas(),
    loginSagas(),
    navigationSagas(),
    homeSagas(),
    ZWebReactCommons.sagas(),
    ZWebAuthenticated.sagas()]);
}

// reducers
const appReducer = combineReducers(
  {
    app: appReducers,
    home: homeReducers,
    login: loginReducers,
    navigation: navigationReducers,
    [ZWebReactCommons.reducersPath]: ZWebReactCommons.reducers,
    [ZWebAuthenticated.reducersPath]: ZWebAuthenticated.reducers,
  });

const rootReducer = (state, action) => {
  if (action.type === actionTypes.RESET_APP) {
    state = undefined
  }

  return appReducer(state, action)
}

// sagas
const sagaMiddleware = createSagaMiddleware();

const middlewares = [...getDefaultMiddleware(), logger, sagaMiddleware];

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: middlewares
});
// run sagas
sagaMiddleware.run(sagas);

export default store;
