import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import { getNoveltiesTokenRequest} from "../modules/home/actions";

const useGetNoveltiesAccessToken = () => {
    const dispatch = useDispatch();
    return useCallback(({resolve, reject}) => {
      dispatch(getNoveltiesTokenRequest({resolve, reject}));
    }, []);
  };

export default useGetNoveltiesAccessToken;