import _ from 'lodash';
import {MODULE_NAME} from "./actions"
import { State } from './typings';


// From zWeb Authenticated
const AUTHENTICATED_REDUCERS_PATH = 'zweb-authenticated'
export const userLogged = (state) => (_.get(state, `${AUTHENTICATED_REDUCERS_PATH}.userData`));
export const entitySelected = (state) => (_.get(state, `${AUTHENTICATED_REDUCERS_PATH}.entitySelected`));

// From This module
export const getFtwPhones = (state: State) =>  state[MODULE_NAME].ftwPhones;

export default {
    userLogged,
    entitySelected,
    getFtwPhones
}