import * as React from "react"

const LogoZetti = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 92.363 63.171"
    height={20}
    width={20}
    {...props}
  >
    <g
      style={{
        opacity: 0.998,
        fill: "#ffffff",
        fillOpacity: 1,
      }}
    >
      <g
        style={{
          fill: "#ffffff",
          fillOpacity: 1,
        }}
      >
        <path
          style={{
            fill: "#ffffff",
            fillOpacity: 1,
            stroke: "none",
          }}
          d="M507.371 557.787h-4.045c-5.867 0-11.474-3.295-12.457-7.323l-.102-.412-.517-2.11-1.666-6.816c-.303-1.25.936-2.273 2.756-2.273h13.611c5.867 0 11.471 3.294 12.457 7.321l.162.668.33 1.353 1.791 7.319c.307 1.25-.933 2.273-2.755 2.273z"
          transform="matrix(1.76491 0 0 -1.76491 -862.227 1014.2)"
        />
      </g>
      <g
        style={{
          fill: "#ffffff",
          fillOpacity: 1,
        }}
      >
        <path
          style={{
            fill: "#ffffff",
            fillOpacity: 1,
            stroke: "none",
          }}
          d="M532.901 557.787h-2.62c-3.799 0-7.494-2.136-8.214-4.742l-.075-.269-.377-1.367-1.211-4.413c-.227-.808.557-1.471 1.736-1.471h8.813c3.799 0 7.496 2.135 8.215 4.742l.117.432.242.876 1.307 4.739c.221.809-.562 1.473-1.739 1.473z"
          transform="matrix(1.76491 0 0 -1.76491 -862.227 1014.2)"
        />
      </g>
      <g
        style={{
          fill: "#ffffff",
          fillOpacity: 1,
        }}
      >
        <path
          style={{
            fill: "#ffffff",
            fillOpacity: 1,
            stroke: "none",
          }}
          d="M529.798 574.646h-3.029c-4.396 0-8.593-2.467-9.329-5.484l-.079-.309-.387-1.582-1.248-5.104c-.228-.937.699-1.702 2.065-1.702h10.195c4.395 0 8.594 2.468 9.332 5.482l.12.5.247 1.014 1.34 5.483c.229.936-.697 1.702-2.06 1.702z"
          transform="matrix(1.76491 0 0 -1.76491 -862.227 1014.2)"
        />
      </g>
      <g
        style={{
          fill: "#ffffff",
          fillOpacity: 1,
        }}
      >
        <path
          style={{
            fill: "#ffffff",
            fillOpacity: 1,
            stroke: "none",
          }}
          d="M509.289 568.823H507.5c-2.588 0-5.109-1.454-5.6-3.232l-.048-.181-.259-.933-.828-3.01c-.153-.551.384-1.002 1.187-1.002h6.009c2.59 0 5.109 1.456 5.601 3.233l.082.294.164.599.889 3.232c.152.551-.379 1-1.183 1z"
          transform="matrix(1.76491 0 0 -1.76491 -862.227 1014.2)"
        />
      </g>
    </g>
  </svg>
)

export default LogoZetti
