import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { ZWebAuthenticated } from '../../../../services';
import t from './translations';
import { withRouter } from 'react-router-dom';
import { IconButton, InputAdornment, createStyles, makeStyles } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { ZButton, ZCheckbox, ZWIDTH_MD, ZWIDTH_SM, ZWIDTH_TINY, useScreenWidth } from '@zetti/zweb-react-commons';
import {
  ZCOLOR_050,
  ZCOLOR_090,
  ZCOLOR_NEGATIVE,
  ZCOLOR_ZETTI_BRAND,
} from '@zetti/zweb-react-commons/dist/components/ui/ZColorPalette';
import { useLogin } from '../../hooks';
import { WarningIcon } from '@zetti/zweb-react-commons/dist/components/ui/icons';
import { ZettiBrandLogo } from '..';
import { useServerAlias } from '../../../app/hooks';

const LoginFormComponent = ({ loginError, loading, location, goRecoveryForm }) => {
  const screenWidth = useScreenWidth();
  const useStyles = makeStyles((theme) =>
    createStyles({
      formContainer: {
        width: screenWidth === ZWIDTH_SM || screenWidth === ZWIDTH_TINY ? '360px' : '400px',
        height: '60vh',
        display: 'flex',
        flexDirection: 'column',
      },
      logoContainer: {
        justifyContent: 'center',
        display: 'flex',
      },
      zettiLogo: {
        maxWidth: '170px',
        maxHeight: ' 100%',
        width: 'auto',
        height: 'auto',
        userSelect: 'none',
        pointerEvents: 'none',
        textAlign: 'center',
      },
      composedLogoContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      },
      formTitle: {
        color: ZCOLOR_090,
        marginTop: '3.6vh',
        marginBottom: '5.5vh',
        textAlign: 'center',
        fontFamily: 'Open Sans',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        userSelect: 'none',
        WebkitUserSelect: 'none',
        msUserSelect: 'none',
      },
      textInput: {
        width: '100%',
        fontSize: '16px',
      },
      submitButton: {
        width: '100%',
        maxWidth: '100%',
        marginTop: '4.5vh',
        textTransform: 'initial',
      },
      formOptionLabels: {
        color: ZCOLOR_050,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
      },
      errorContainer: {
        display: 'flex',
        width: '100%',
        padding: '15px',
        alignItems: 'center',
        gap: '10px',
        borderRadius: '5px',
        border: `1px solid ${ZCOLOR_NEGATIVE}`,
        background: 'rgba(255, 45, 45, 0.07)',
        marginBottom: '2.2vh',
      },
      errorLabel: {
        fontSize: '1rem',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        color: ZCOLOR_090,
      },
      checkBoxContainer: { display: 'flex', alignItems: 'center', marginTop: '20px', justifyContent: 'space-between' },
      disabled: {},
    })
  );
  const classes = useStyles({});
  const searchUrl = location.search + location.hash;
  const redirectURL = searchUrl.split('next=')[1];

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [rememberCheckbox, setRememberCheckbox] = useState(false);

  const autoLoginIsEnabled = async () => {
    let bootstrapIsEnabled = await window.localStorage.getItem('zweb-auth-common|AUTH|bootstrapEnabled');
    await setRememberCheckbox(bootstrapIsEnabled == 'true' ? true : false);
  };

  useEffect(() => {
    autoLoginIsEnabled();
  }, []);

  const handleLogin = useLogin();

  useEffect(() => {
    if (userName.length > 0 && password.length > 0) setIsValid(true);
    else setIsValid(false);
  }, [userName, password]);

  useEffect(() => {
    window.localStorage.setItem('zweb-auth-common|AUTH|bootstrapEnabled', rememberCheckbox ? 'true' : 'false');
  }, [rememberCheckbox]);

  // START - Home Cafabo
  const serverAlias = useServerAlias()
  const customerIsCafabo = serverAlias.toLowerCase().includes("cafabo")
  // END


  return (
    <div className={classes.formContainer}>
      <div className={classes.logoContainer}>
        {!customerIsCafabo && <img
          src={ZettiBrandLogo}
          draggable={false}
          onSelect={(event) => event.preventDefault()}
          className={classes.zettiLogo}
        />}
        {customerIsCafabo && <div className={classes.composedLogoContainer}>
          <img
            src="images/cafabo_logo.png"
            alt="Cafabo"
            style={{
              width: '220px', height: '81.54px', userSelect: 'none', cursor: 'pointer',
              objectFit: "cover"
            }}
            draggable={false}
            onSelect={(event) => event.preventDefault()}
            onClick={e => window.open("https://zetti.com.ar/", '_blank')}
          />
          <img
            src="images/powered_by_zetti_logo.png"
            alt="Powered By Zetti"
            style={{ width: '138px', height: '20.5px', userSelect: 'none', margin: "0 auto", cursor: 'pointer' }}
            draggable={false}
            onSelect={(event) => event.preventDefault()}
            onClick={e => window.open("https://zetti.com.ar/", '_blank')}
          />
        </div>}
      </div>
      <span className={classes.formTitle}>{t('formTitle')}</span>
      {loginError && (
        <div>
          <div className={classes.errorContainer}>
            <WarningIcon fill={ZCOLOR_NEGATIVE} style={{ height: '2rem' }} />
            <span className={classes.errorLabel}>{t('wrongCredentials')}</span>
          </div>
        </div>
      )}

      <TextField
        autoFocus={true}
        className={classes.textInput}
        label={t('username')}
        onChange={(event) => setUserName(event.target.value)}
      />
      <TextField
        InputLabelProps={{ style: { fontSize: '12px' } }}
        type={showPassword ? 'text' : 'password'}
        className={classes.textInput}
        style={{ marginTop: '20px' }}
        label={t('password')}
        onChange={(event) => setPassword(event.target.value)}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            ev.preventDefault();
            if (isValid) {
              handleLogin(userName, password, redirectURL);
            }
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)}>
                {!showPassword ? (
                  <Visibility style={{ fill: ZCOLOR_ZETTI_BRAND }} />
                ) : (
                  <VisibilityOff style={{ fill: ZCOLOR_ZETTI_BRAND }} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <div className={classes.checkBoxContainer}>
        {/* <ZCheckbox
                    checked={rememberCheckbox}
                    onChange={() => setRememberCheckbox(!rememberCheckbox)}
                    label={t("rememberMe")}
                    labelProps={{ style: styles.formOptionLabels }}
                    style={{ justifyContent: "left" }}
                /> */}
        <span
          className={classes.formOptionLabels}
          style={{ display: 'flex', justifyContent: 'right', cursor: 'pointer', width: '100%' }}
          onClick={goRecoveryForm}
        >
          {t('recoveryPassword')}
        </span>
      </div>

      <div>
        <ZButton
          action={() => handleLogin(userName, password, redirectURL)}
          label={t('loginBtn')}
          disabled={!isValid || loading}
          zVariant="primary"
          zSize="large"
          style={{ width: '100%', maxWidth: '100%', marginTop: '4.5vh', textTransform: 'initial' }}
        // className={classes.submitButton}
        />
      </div>
    </div>
  );
};

export default ZWebAuthenticated.hocs.WithTranslationRerender(withRouter(LoginFormComponent));
