import React, { useEffect, useState } from "react";
import { ZButton, ZWIDTH_SM, ZWIDTH_TINY, useScreenWidth } from "@zetti/zweb-react-commons";
import { createStyles, makeStyles } from "@material-ui/styles";
import { ZCOLOR_060 } from "@zetti/zweb-react-commons/dist/components/ui/ZColorPalette";
import { CampaignIcon, HistoryEduIcon, MovieIcon, SchoolIcon, SupportAgentIcon, WhatsappIcon } from "@zetti/zweb-react-commons/dist/components/ui/icons";
import { useGetFtwPhones, useGetNoveltiesAccessToken, useSearchFtwPhones } from "../../../hooks";


const HomeFooter = ({ ...restOfProps }) => {

    const useStyles = makeStyles((theme) => createStyles({
        footerContainer: {
            width: "100%",
            height: "80px",
            borderRadius: "8px",
            border: `1px solid ${ZCOLOR_060}`,
            alignItems: "center",
            display: "flex",
            paddingLeft: "30px",
            paddingRight: "30px",
            color: ZCOLOR_060,
            fontFamily: "Open Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal"
        },
        leftSection: {
            justifyContent: "flex-start",
            display: "inline-flex",
            maxWidth: "max-content",
            gap: "30px",
            "& span": {
                display: "flex",
                minWidth: "max-content",
                gap: "5px"
            }
        },
        rightSection: {
            justifyContent: "flex-end",
            display: "inline-flex",
            width: "100%",
            gap: "10px"
        }
    }))

    const classes = useStyles()


    const WPP_BASE_LINK = 'https://wa.me/549'
    const ZETTI_UPDATES_LINK = 'https://sites.google.com/zetti.com.ar/actualizaciones/'
    const ZETTI_VIDEO_TUTORIALS_LINK = "https://zetti.com.ar/videostutoriales"
    const NOVELTIES_BASE_LINK = "https://zmaestros.zetti.com.ar/ftweb/novedades.jsp?hash="

    const getNoveltiesToken = useGetNoveltiesAccessToken()
    const searchFtwPhones = useSearchFtwPhones()
    const ftwPhones = useGetFtwPhones()

    const handleOpenNovelties = async () => {
        new Promise((resolve, reject) => {
            getNoveltiesToken({ resolve, reject })
        }).then(token => {
            window.open(NOVELTIES_BASE_LINK + token, '_blank')
        })
    }

    useEffect(() => {
        new Promise((resolve, reject) => {
            searchFtwPhones({ resolve, reject })
        })
    }, [])

    const screenWidth = useScreenWidth()

    const [smallResolution, setSmallResolution] = useState<boolean>(null)

    useEffect(() => {
        const yes: boolean = screenWidth === ZWIDTH_TINY || screenWidth === ZWIDTH_SM
        setSmallResolution(yes)
    }, [screenWidth])

    return (<div style={{
        display: "flex",
        overflow: "hidden",
        justifyContent: "center",
        marginTop: "auto",
        padding: '30px',
    }}
        {...restOfProps}
    >
        <footer className={classes.footerContainer}>
            <section className={classes.leftSection}>
                <span>
                    ¿Necesitás ayuda?
                </span>
                {ftwPhones && ftwPhones.map(phone => {
                    if (phone.type.description === "SOPORTE")
                        return <span>
                            <SupportAgentIcon fill={ZCOLOR_060} />
                            {`${phone.number}`}
                        </span>

                })}
                {ftwPhones && ftwPhones.map(phone => {
                    if (phone.type.description === "WHATSAPP")
                        return <span
                            style={{ cursor: "pointer" }}
                            onClick={() => window.open(`${WPP_BASE_LINK}${phone.prefix}${phone.number.replace(new RegExp("-"), "")}`, '_blank')}
                        >
                            <WhatsappIcon fill={ZCOLOR_060} />
                            {`${phone.prefix}-${phone.number}`}
                        </span>
                })}
            </section>
            <section className={classes.rightSection}>
                <ZButton
                    label={!smallResolution && "Actualizaciones"}
                    action={() => window.open(ZETTI_UPDATES_LINK, '_blank')}
                    Icon={CampaignIcon}
                    iconProps={{ fill: ZCOLOR_060, style: { marginRight: smallResolution ? 0 : "5px" } }}
                    zSize="medium"
                    style={{ color: ZCOLOR_060 }}
                />
                <ZButton
                    label={!smallResolution && "Tutoriales"}
                    action={() => window.open(ZETTI_VIDEO_TUTORIALS_LINK, '_blank')}
                    Icon={MovieIcon}
                    iconProps={{ fill: ZCOLOR_060, style: { marginRight: smallResolution ? 0 : "5px" } }}
                    zSize="medium"
                    style={{ color: ZCOLOR_060 }}
                />
                <ZButton
                    label={!smallResolution && "Datos"}
                    action={handleOpenNovelties}
                    Icon={HistoryEduIcon}
                    iconProps={{ fill: ZCOLOR_060, style: { marginRight: smallResolution ? 0 : "5px" } }}
                    zSize="medium"
                    style={{ color: ZCOLOR_060 }}
                />
            </section>
        </footer>
    </div>)

}

export default HomeFooter;