import React from 'react';
import {ZWebAuthenticated} from '../../../services';
import {connect} from 'react-redux';
import {Route} from "react-router-dom";
import {Redirect, withRouter} from 'react-router';
import {selectors as appSelectors} from '../../app';

const PrivateRouteComponent = ({isLogged, entitySelected, appBootstraped, ...props}) => {

  if (!appBootstraped) {
    return null;
  }

    if (!isLogged) {
        return <Redirect to="/login"/>;
    }

    if (!entitySelected) {
        return <Redirect to="/select-entity"/>;
    }
  return <Route {...props} />;
};

const mapStateToProps = (state) => ({
  isLogged: ZWebAuthenticated.selectors.isLogged(state),
  entitySelected: ZWebAuthenticated.selectors.entitySelected(state),
  appBootstraped: appSelectors.isBootstraped(state)
});

export default withRouter(connect(mapStateToProps, null)(PrivateRouteComponent));
