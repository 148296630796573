import { ZWebAuthenticated } from '../../../../services';

export default ZWebAuthenticated.registerTranslations('LoginFormComponent', {
    es: {
        passRecoveryTitle: "¿Olvidaste tu contraseña?",
        passRecoveryLabel: "Si olvidaste tu contraseña, por favor contacta con el administrador de tu equipo.",
        backBtn: "Regresar al Login"
    },
    pt: {
        passRecoveryTitle: "Você esqueceu sua senha?",
        passRecoveryLabel: "Se você esqueceu sua senha, entre em contato com o administrador da equipe.",
        backBtn: "Volte"
    },
    en: {
        passRecoveryTitle: "Did you forget your password?",
        passRecoveryLabel: "If you forgot your password, please contact your team administrator.",
        backBtn: "Go back"
    }
});